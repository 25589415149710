import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {PlantService} from '../services/plant.service';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup  } from '@angular/forms';
import { ReadSectionGroupService } from '../services/read-section-group.service'

import { GeaMesCognitoAuthService } from '@gea-mes/cognito';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent  implements OnInit ,DoCheck {

  formGroup: UntypedFormGroup;
  routeParams:any=[];
  selectedPlant:string;
  selectedShift:string;
  hideDropDowns:boolean=false;
  sessionReady$:Subscription;

  
  
  constructor(private auth: GeaMesCognitoAuthService, public readSectionGroupService:ReadSectionGroupService,   public plantService:PlantService,private formBuilder: UntypedFormBuilder, private router: Router, private route: ActivatedRoute){
    this.formGroup = this.formBuilder.group({
      plant:[]
    });


    
  }
  ngOnInit() {
    // Protect from session not being ready before attempting to make a back in call
    this.sessionReady$ = this.auth.sessionReady$.subscribe(ready => {   
      console.debug("Session Ready Status", ready);
      if (ready) {
        if (this.sessionReady$) this.sessionReady$.unsubscribe();

        this.route.queryParams.subscribe((params) => {
          this.routeParams=params;
        });

        this.plantService.getPlantList().subscribe(res => {
        this.plantService.loadPlantList(res);
        if(this.plantService.selectedPlant!=undefined){
        
          this.formGroup.get('plant').setValue(this.plantService.selectedPlant);
          }
        });
        }
    });
  }

  ngDoCheck() {
    //Logic to dynamically highlght Configuration nav item for UpdeConfig Screen
    this.router.events.subscribe((val: any) => {
      if (val.url == "/updateConfiguration") {
        this.formGroup.disable();
      }else {
        this.formGroup.enable();
      }
      
      if (val.url == "/logout") {
        this.hideDropDowns=true;
      }   
    });
    }

  

  setPlantValue(){
    this.plantService.selectedDepartment=null;
    this.plantService.setPlantSelection();
    this.selectedPlant=this.formGroup.get('plant').value;
    this.plantService.setPlantValue(this.formGroup.get('plant').value);
    for(var i=0; i< this.plantService.plantList.length; i++){
      if(this.plantService.plantList[i].plant == this.formGroup.get('plant').value){
        this.plantService.selectedDepartment= this.plantService.plantList[i].department;
      }
    }
  }
  setLineValue(){
     
      this.plantService.setLineValue(this.formGroup.get('plant').value);
      this.selectedShift=this.formGroup.get('plant').value;
      this.loadSectionGroup()

  }

  

  
  loadSectionGroup(){
    let obj={};
    obj['site']=this.selectedPlant;
    obj['shift'] =this.selectedShift;
    //this.readSectionGroupService.getReadSectionGroup(obj).subscribe(res => {
   
 
    //});
    this.readSectionGroupService.getReadSectionGroup(obj);
  }

}
