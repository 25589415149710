<h3 class="headerConfiguration" *ngIf="type">Create New Attendance Section Group</h3>
<h3 class="headerConfiguration" *ngIf="!type">Update Attendance Section Group</h3>
<form [formGroup]="formGroup">
  <div class="parent">
    
    <table class="plantTable">
        <mat-form-field class="demo-full-width" appearance="outline" class="sectionGroupInput">
            <mat-label>Custom Section Group Name</mat-label>
            <input matInput placeholder="Custom Section Group Name" formControlName="sectionGroupName" width="50%" required >
          </mat-form-field>
          <button mat-raised-button color="Accent-red" style="float:right" (click)="openDialog()" class="delete" *ngIf="!type">Delete Section Group</button>
          <div class="container">
            <!-- <span class="availableSections" style="color:#375D9D"><b>Available Sections</b></span>
            <span class="assignedSections"  style="color:#375D9D"><b>Assigned Sections</b></span> -->
            <dual-list [source]="source" [(destination)]="target" height="150px" [format]="format"></dual-list>
          </div>
     <div style="width:100%;margin-top: 20px !important" class="buttonGroup">
            <button mat-raised-button color="primary" class="submit" (click)="saveSectionGroupName()" *ngIf="type">Submit</button>
            <button mat-raised-button color="primary" class="submit" (click)="saveSectionGroupName()" *ngIf="!type">Update</button>
            <button mat-raised-button color="primary" type="reset" routerLink="/summary" class="cancel">Cancel</button>
     </div>
    </table>
  </div>
</form>
