import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../src/environments/environment';
import {configurationModel} from '../configurationModel';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  readonly  createUpdateSectionGroup= environment.apigwUrl + "/createUpdateSectionGroup";
  readonly  deleteSectionGroup= environment.apigwUrl + "/deleteSectionGroup";
  constructor(private http: HttpClient) { }
  postConfigaration(configuration: object) {
    return this.http.put(this.createUpdateSectionGroup, configuration);
  }
  deleteConfiguration(configuration: object) {
    return this.http.put(this.deleteSectionGroup, configuration);
  }
  
}