import { Component, OnInit,Input,ViewChild  } from '@angular/core';
import {ReadSectionGroupService} from '../services/read-section-group.service'
import {configuration} from '../configuration'
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table'
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { Router } from '@angular/router';

export interface PeriodicElement {
  SectionGroupName: string;
  SectionList: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-plant-summary',
  templateUrl: './plant-summary.component.html',
  styleUrls: ['./plant-summary.component.scss']
})
export class PlantSummaryComponent implements OnInit {

  sectionGroupData :any;
  displayedColumns: string[] = ['SectionGroupName','SectionList'];

  constructor(private readSectionGroupService:ReadSectionGroupService,private router: Router) { }
  
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {

    this.readSectionGroupService.currentMessage.subscribe(
      data => { 
        if(data!=undefined){
        this.sectionGroupData = new MatTableDataSource(data);
        this.sectionGroupData.sort=this.sort;
        }
      });
  }

  sectionGroupselect(configure: configuration){
    this.readSectionGroupService.setSelectedConfiguration(configure);
    this.readSectionGroupService.selectedAssingedSections=[];
    if(configure['SectionGroupName']!="Unassigned Sections"){
      this.readSectionGroupService.setAssignedSections(configure['SectionList']);
      this.router.navigate(["/updateConfiguration"]);
    }else{
      this.router.navigate(["/configuration"]);
    }
  }

}
