<div>
<div class="deletepopup"  style="margin-left: -24px; margin-right: -24px;">
	<h5 mat-dialog-title   class="deleteHeader">Alert!&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delete Attendance Section Group</h5>
</div>
    <div>
	<mat-dialog-content>
		 <p class="deleteMessage">
			 Deleting the section group would unmap all the sections associated with it
		   </p>
		<p class="deleteMessage">
			{{message}}
		</p>
	</mat-dialog-content>
	<mat-dialog-actions align="center">
		<button mat-raised-button color="primary" class="cancelButton" mat-dialog-close tabindex="-1" *ngIf="showCancelButton">{{cancelButtonText}}</button>
		<button mat-raised-button class="deleteButton"(click)="onConfirmClick()" tabindex="1">{{deleteButtonText}}</button>
</mat-dialog-actions>
      </div>
</div>