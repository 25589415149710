import { Component, OnInit,Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef,  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

 message: string = "Are you sure?"
  cancelButtonText = "Cancel"
  deleteButtonText = "Delete"
  showCancelButton: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
      if(data){
    this.message = data.message || this.message;
    if (data.buttonText) {
      this.deleteButtonText = data.buttonText.ok || this.deleteButtonText;
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      if(data.buttonText.cancel!=undefined){
        this.showCancelButton=true;
      }
    }
      }
  }
  ngOnInit() {
  }
  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
