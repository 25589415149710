
<div class="btnDiv">
     <a mat-raised-button class="box createNewBtn" routerLink="/configuration">Create New</a>
</div>




<div class="parent">
<table mat-table [dataSource] = "sectionGroupData"  class="plantSummaryTable" matSort [matSortActive]="sort.active" matSortDirection="asc"  > 
  
  <ng-container matColumnDef = "SectionGroupName">
     <th  mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]=" 'headerStyleSectionGroup'" >Section Group Name</th>
    
     <td  mat-cell *matCellDef = "let element" [ngClass]="'colStyleSectionGroup'"  style="cursor:pointer"> {{element.SectionGroupName}} </td>
   
  </ng-container>
  <ng-container matColumnDef = "SectionList">
   
    <th  mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headerStyleSectionList'" >Assigned Sections</th>
    <td     mat-cell *matCellDef = "let element" [ngClass]="'colStyleSectionList'" style="cursor:pointer">{{element.SectionList}} </td>

  </ng-container>
  <tr mat-header-row *matHeaderRowDef = "displayedColumns"></tr>
  <tr mat-row *matRowDef = "let row; columns: displayedColumns;" (click)="sectionGroupselect(row)"></tr>
</table>
</div>





