import { Component, OnInit, IterableDiffers, DoCheck } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReadSectionGroupService } from '../services/read-section-group.service'
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import {PlantService} from '../services/plant.service';
import {configurationModel} from '../configurationModel';
import {ConfigurationService} from '../services/configuration.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit,DoCheck {
  configurationModel:configurationModel;
  assignedSectionList = [];
  type: boolean = true;
  source = [...this.readSectionGroupService.sectionStringArray];
  assignedValues = [];
  target=[];
  differ: any;
  format = { add: '>>', remove: '<<', all: 'Alle', none: 'Intet'};
  formGroup: UntypedFormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private formBuilder: UntypedFormBuilder,public readSectionGroupService:ReadSectionGroupService,private dialog: MatDialog,
    private route: ActivatedRoute,public plantService:PlantService,private _router: Router,public configurationService: ConfigurationService,private _snackBar: MatSnackBar,differs: IterableDiffers) {
    this.formGroup = this.formBuilder.group({
      sectionGroupName: []
    });
    this.differ = differs.find([]).create(null); 
  }

   ngOnInit() {
    this.route.data.subscribe(v => {
      if (v.type == 'update') {
        this.type = false;
        this.formGroup.setValue({
          sectionGroupName:  this.readSectionGroupService.configurationValues.SectionGroupName
        })
        if(this.readSectionGroupService.selectedAssingedSections.length>0){
          let str=this.readSectionGroupService.selectedAssingedSections;
          this.source=[];
          this.source=[...this.readSectionGroupService.availabeSectionsGlobal];
          for(var i=0;i<this.readSectionGroupService.selectedAssingedSections.length;i++){
            this.source.push(this.readSectionGroupService.selectedAssingedSections[i]);
            this.target.push(this.readSectionGroupService.selectedAssingedSections[i])
          }
          
        }
      }else{
        this.source=[...this.readSectionGroupService.availabeSectionsGlobal];
      } 
    });
    
   }

   ngDoCheck() {
    const change = this.differ.diff(this.readSectionGroupService.sectionStringArray);
    if(change){
      this.source=this.readSectionGroupService.availabeSectionsGlobal;
      this.target=[];
    }
  }
  
   openDialog() {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: 'Are you sure want to delete?',
          buttonText: {
            cancel: 'Cancel',
            ok: 'Delete'
          }
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          const a = document.createElement('a');
          a.click();
          a.remove();
          let inputParams ={};
          var groupName=this.formGroup.get('sectionGroupName').value;
          var department=this.plantService.selectedDepartment;
          var shift = this.readSectionGroupService.shiftValue;
          inputParams['department']=department;
          inputParams['sectionGroupName']= groupName;
          inputParams['shift']= shift;
          inputParams['sectionList']= this.target;
          this.configurationService.deleteConfiguration(inputParams).subscribe(res => {
            let statusMessage = res['statusMessage'];
             this._snackBar.open("Record Deletion Success"," ", {
               duration: 5000,
               horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,
               panelClass: ['alert-success'],
             });
            if(statusMessage=="SectionGroupName Deleted Successfully"){
              this.refreshSummaryPage();
              this._router.navigate(["/summary"]);
            }
 
           });
        }
      });

  }
  destinationValue(event: any){

  }
  saveSectionGroupName(){
    let inputParams ={};
    var department=this.plantService.selectedDepartment;
    var shift = this.readSectionGroupService.shiftValue; 
    var groupName=this.formGroup.get('sectionGroupName').value;
    inputParams['department']=department;
    inputParams['sectionGroupName']= groupName;
    inputParams['shift']= shift;
    inputParams['sectionList']= this.target;
    if(this.type){
      if(groupName==null || groupName=="" ){
        this._snackBar.open("Please enter section group", "", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
         panelClass: ['alert-error'],
        });
      }else if(this.target.length == 0){
        this._snackBar.open("Please select section values", "", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
         panelClass: ['alert-error'],
        });
      }
        else{
       this.configurationService.postConfigaration(inputParams).subscribe(res => {
      this._snackBar.open("Record creation Success", " ", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
       panelClass: ['alert-success'],
      });
      this.refreshSummaryPage();
      this._router.navigate(["/summary"]);
    });
      }
    }else{
        this.configurationService.postConfigaration(inputParams).subscribe(res => {
        this._snackBar.open("Record Updation Success", " ", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['alert-success'],
       });
       this.refreshSummaryPage();
       this._router.navigate(["/summary"]);
     });
    }
  
  }
   refreshSummaryPage(){
    let obj={};
    obj['shift'] = this.readSectionGroupService.shiftValue;
    obj['site'] = this.readSectionGroupService.plantValue;
    this.readSectionGroupService.getReadSectionGroup(obj);
   }
  
}
