import { Component, DoCheck, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LogOutService } from "../services/log-out.service";


@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent implements OnInit, DoCheck {
  highlightConfigNavItem: boolean = false;

  constructor(private router: Router, public logOutService: LogOutService) {}

  ngOnInit() {}

  ngDoCheck() {
    //Logic to disbale plant / shift dropdown for Update config screen
    this.router.events.subscribe((val: any) => {
      if (val.url == "/updateConfiguration" || val.url == "/configuration") {
        this.highlightConfigNavItem = true;
      } else {
        this.highlightConfigNavItem = false;
      }
    });
  }
}
