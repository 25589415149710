import { Component, Inject, OnInit } from '@angular/core';
import { GEA_MES_NAVBAR_MENU_SERVICE } from '@gea-mes/navbar';
import { BehaviorSubject } from 'rxjs';
import {NavItemService,NavItem} from './nav-item.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor( public navService:NavItemService,

    @Inject(GEA_MES_NAVBAR_MENU_SERVICE) private NavItem: BehaviorSubject<NavItem[]>) { 
    NavItem.next(navService.navItems);
    }

  ngOnInit() {}
}