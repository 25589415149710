import { BrowserModule } from '@angular/platform-browser';
import { NgModule,ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';  

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavComponent } from './nav/nav.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav'; 
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'; 
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'; 
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list'; 
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatRippleModule } from '@angular/material/core'; 
  

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LogOutPageComponent } from './log-out-page/log-out-page.component';
import { PlantSummaryComponent } from './plant-summary/plant-summary.component';

import { ConfigurationComponent } from './configuration/configuration.component';
import { DropDownComponent } from './drop-down/drop-down.component';
import { ReactiveFormsModule } from '@angular/forms';
import {PlantService} from './services/plant.service';
import {ConfigurationService} from './services/configuration.service';
import {MatSortModule} from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {GeaMesNavbarModule}from '@gea-mes/navbar';
import {NavItem,NavItemService} from './nav-item.service';
import { MainContentComponent } from './main-content/main-content.component';


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        NavComponent,
        LogOutPageComponent,
        PlantSummaryComponent,
        ConfigurationComponent,
        DropDownComponent,
        ConfirmationDialogComponent,
        MainContentComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        BrowserAnimationsModule,
        MatCardModule,
        MatSidenavModule,
        MatListModule,
        ReactiveFormsModule,
        MatSelectModule, MatSidenavModule, MatToolbarModule,
        MatRippleModule, MatFormFieldModule, MatButtonModule,
        MatIconModule, MatGridListModule, MatCardModule,
        MatInputModule, MatListModule, MatSnackBarModule, MatDialogModule,
        MatRadioModule, CommonModule, MatTableModule, MatSortModule, AngularDualListBoxModule,
        GeaMesNavbarModule
    ],
    providers: [PlantService, ConfigurationService, NavItemService],
    bootstrap: [AppComponent]
})
export class AppModule { }

