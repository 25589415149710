import { Injectable  } from '@angular/core';
import { AbstractRestService } from '@gea-mes/cognito';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class PlantService  extends AbstractRestService{


  postInit(): void {
    console.log("Post INIT!");
     
  }

  plantList:any[];
  plantConfigList:any[];
  shiftConfigList:any[];
  selectedPlant:string;
  selectedDepartment:string;
  selectedShift:string;
  plantSelection:boolean=false;
  plant_dropdown_selected_value:String;
  
  getPlantList():Observable<any> {
    return this.get({
      ApiResource: '/attendancecfg/getPlantShift',
      data: {fromObject: null},
      repeatCall: false
    })
  }
  
  setPlantValue(val){
   
    this.selectedPlant=val;
    this.plant_dropdown_selected_value = val;
    this.shiftConfigList = this.lodaLineList(this.plantConfigList);
  }
 
  setLineValue(val){
     this.selectedShift=val;

  }
  setPlantSelection(){
    
    this.plantSelection=true;
  }

  lodaLineList(obj){

    for (let i = 0; i < Object.keys(obj).length; i++) {
        if(obj[i].plant==this.plant_dropdown_selected_value){
          console.log(obj[i].shifts);
          return obj[i].shifts;
        }
       
    }
  }
  loadPlantList(Obj){

    
    this.plantList=Obj.plants;
    // Sorting list alphabatically
    this.plantList.sort(function (x, y) {
      let a = x.plant.toUpperCase(),
      b = y.plant.toUpperCase();
     return a == b ? 0 : a > b ? 1 : -1;
   });

    this.plantConfigList=Obj.plants;
  }
}

