import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription} from 'rxjs';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { environment } from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogOutService {
  public isLoggedIn: BehaviorSubject<boolean>
  public isLogged: boolean
  userName:String
  userNameStatus =false;

  clientIdPath =  "CognitoIdentityServiceProvider."+environment.cognitoOptions.ClientId+".";

  cognitoPayload:any
  loginId:String
  UserNameValue:String
  checkLoginUserStatus:boolean=false
  public UserDetails :{};
  sessionActive$: Subscription;
  constructor(private router: Router, private cognito: GeaMesCognitoAuthService) {
    this.isLoggedIn = new BehaviorSubject(true)

    this.isLoggedIn.subscribe((userStatus) =>{
    this.isLogged = userStatus
  })

  this.sessionActive$ = this.cognito.sessionEstablished$.subscribe(x => {
    this.userName= this.getLoginUserDeatils();
    if(this.userName!=''){
      this.userNameStatus=true;
      this.checkLoginUserStatus =true
    }
    if(this.sessionActive$){
      this.sessionActive$.unsubscribe();}
 });

  }

  logOut(){
    this.isLoggedIn.next(false)
    this.cognito.signOutUser();
  }

  getLoginUserDeatils(){
    this.UserNameValue=this.cognito.getUserAttribute('lastName') +" "+ this.cognito.getUserAttribute('firstName') + "(" + this.cognito.getUserAttribute('sso') + ")";
    return this.UserNameValue;
   }
}
