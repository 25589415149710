import { Injectable  } from '@angular/core';
import { AbstractRestService } from '@gea-mes/cognito';
import { BehaviorSubject } from 'rxjs';
import {configuration} from '../configuration'
@Injectable({
  providedIn: 'root'
})


export class ReadSectionGroupService  extends AbstractRestService {
  sectionStringArray = [];
  shiftValue:string;
  plantValue:string;
  configurationValues: configuration;
  Data:any;
  private DataSource = new BehaviorSubject(null);
  currentMessage = this.DataSource.asObservable();
  selectedAssingedSections=[];
  availabeSectionsGlobal=[];

  postInit(): void {
  }

  changeMessage(data:any) {
    this.DataSource.next(data)
  }

  getReadSectionGroup(obj:any){
    this.availabeSectionsGlobal=[];
    this.shiftValue=  obj['shift'];
    this.plantValue=  obj['site'];
    if(this.sectionStringArray.length>0){
      this.sectionStringArray.splice(0,this.sectionStringArray.length);
    }
    this.get({
      ApiResource: '/attendancecfg/readSectionGroup',
      data: obj,
      repeatCall: false
    }).subscribe(data=>{
       for(let selection of data['data']){
        if(selection['SectionGroupName']=='Unassigned Sections'){
          var array=selection['SectionList'].split(",");
          for(let strarray of array){
            this.sectionStringArray.push(strarray);  
          }
          this.availabeSectionsGlobal=this.sectionStringArray;
        }
       }

      this.changeMessage(data['data']);
    });
    return  "";
  }

  setSelectedConfiguration(config: configuration) {
    this.configurationValues = config;
  }

  setAssignedSections(str){
    this.sectionStringArray=[];
    this.selectedAssingedSections=str.split(",");
  }
}
