import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { GeaMesLoginComponent, 
         GeaMesCognitoAuthGuard, 
         GeaMesCognitoModule, 
         COGNITO_CONFIG, 
         GeaMesAuthInterceptor, 
         APPLICATION_NAME, 
         ALLOW_SCREENAUTH, 
         APIGATEWAY_CONFIG } from '@gea-mes/cognito';
import { environment } from 'src/environments/environment';
import { appinfo } from './appinfo';
import { HomeComponent } from './home/home.component'
import {PlantSummaryComponent} from './plant-summary/plant-summary.component'
import {ConfigurationComponent} from './configuration/configuration.component'

import {LogOutPageComponent} from './log-out-page/log-out-page.component'
import {GEA_MES_NAVBAR_COGNITO_CONFIG,GEA_MES_NAVBAR_APPLICATION_NAME,
        GEA_MES_NAVBAR_COGNITO_AUTH_SERVICE,GEA_MES_NAVBAR_MENU_SERVICE,
        GEA_MES_NAVBAR_ENV_PROD_STATUS, GEA_ENVIROMENT, NavItem,
        GEA_MES_NAVBAR_APP_ID_FOR_WHATSNEW_TEXT} from '@gea-mes/navbar';
import {GeaMesCognitoAuthService} from '@gea-mes/cognito';
import {BehaviorSubject} from 'rxjs';


const routes: Routes = [
  {path: 'login', component: GeaMesLoginComponent},
  {path: 'logout', component: LogOutPageComponent},
  {
      path: '', canActivate: [GeaMesCognitoAuthGuard],
      children: [
      {path: '', redirectTo:'/summary', pathMatch: 'full'},
      { path: 'summary', component: PlantSummaryComponent },
      {path: 'home', component: HomeComponent},
      {path: 'updateConfiguration', component: ConfigurationComponent,data: { type: 'update' }},
      {path: 'configuration', component: ConfigurationComponent,data: { type: 'create' }},
   
      ]
  },
  {path: '**', redirectTo:'/summary' }
  ];

  @NgModule({
    imports: [GeaMesCognitoModule, RouterModule.forRoot(routes, {})],
    providers: [
        { provide: COGNITO_CONFIG, useValue: environment.cognitoOptions },
        { provide: HTTP_INTERCEPTORS, useClass: GeaMesAuthInterceptor, multi: true },
        { provide: GEA_MES_NAVBAR_APPLICATION_NAME,useValue:"Attendance Configuration"}, 
        { provide: APPLICATION_NAME, useValue: appinfo.name.toUpperCase() },
        { provide: APIGATEWAY_CONFIG, useValue: environment.apiGateway },
        { provide: ALLOW_SCREENAUTH, useValue: environment.AllowScreenAuth } ,
        { provide: GEA_MES_NAVBAR_COGNITO_CONFIG, useValue: environment.cognitoOptions }, 
        { provide: GEA_MES_NAVBAR_COGNITO_AUTH_SERVICE, useClass: GeaMesCognitoAuthService },
        { provide: GEA_MES_NAVBAR_ENV_PROD_STATUS, useValue: environment.production },
        { provide: GEA_ENVIROMENT, useValue: environment }, 
        { provide: GEA_MES_NAVBAR_MENU_SERVICE, useValue:new BehaviorSubject<NavItem[]>([])},
        { provide: GEA_MES_NAVBAR_APP_ID_FOR_WHATSNEW_TEXT,useValue:"AttendanceConfig"}  
    ],
    exports: [RouterModule]
    })
export class AppRoutingModule { }
