<div class="container">
  <form [formGroup]="formGroup" class="inputForm"  *ngIf ="!hideDropDowns">
    <!-- System Dropdown-->
    <mat-form-field class="slicer">
      <mat-label>Plant</mat-label>
      <mat-select (selectionChange)="setPlantValue()" formControlName="plant" >
        <mat-option value="null" disabled selected>Select...</mat-option>
        <mat-option *ngFor="let sys of plantService.plantList" [value]="sys.plant">
          {{sys.plant}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="plantService.plantSelection">
    <mat-form-field class="slicer">
      <mat-label>Shift</mat-label>
      <mat-select (selectionChange)="setLineValue()" formControlName="plant" >
        <mat-option value="null" disabled selected>Select...</mat-option>
        <mat-option *ngFor="let sys of plantService.shiftConfigList" [value]="sys.shift">
          {{sys.shift}}
        </mat-option>
       
      </mat-select>
    </mat-form-field>

    </ng-container>
    </form>
</div>